import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import * as React from "react";
import MarkdownView from "react-showdown";
import Image from "./image";

const IndexSection = ({ post, mode, preview }) => {
  return (
    <div className="section issue">
      <div className="row gtr-150 gtr-uniform">
        <div className="col-4 col-5-medium col-12-small col">
          <h1>{post.title}</h1>
          <div className={`box ${mode} ball`}>
            <div className="heading">
              <h2>conversation</h2>
              <h3>{post.intervieweeName}</h3>
            </div>
            {post.interviewIntro && (
              <MarkdownView
                markdown={`${post.interviewIntro}`}
                className="conversation-alt"
              />
            )}
            {post.interviewContent && (
              <MarkdownView
                markdown={`${post.interviewContent.substring(
                  0,
                  650 - post.interviewIntro.length
                )}...`}
                className="conversation-alt"
              />
            )}
            <div className="small-top-margin">
              <Link to={`/${post.slug}`} className="button alt">
                Read more
              </Link>
            </div>
          </div>
        </div>
        <div className="col-8 col-7-medium col-12-small col">
          <div className="box white">
            <div className="heading">
              <h2>{post.mainPickTitle}</h2>
              <h3>{post.main_product_pick.name}</h3>
            </div>
            <div className="row gtr-150 gtr-uniform">
              <div className="col-6 col-12-medium">
                <span className="image fit">
                  <Link to={`/shop/${post.main_product_pick.slug}`}>
                    {preview && (
                      <img
                        src={
                          post.main_product_pick.images.length &&
                          post.main_product_pick.images[0].url
                        }
                        alt={
                          post.main_product_pick.images.length &&
                          post.main_product_pick.images[0].alternativeText
                        }
                      />
                    )}

                    {!preview && (
                      <Image
                        image={
                          post.main_product_pick.images.length &&
                          post.main_product_pick.images[0]
                        }
                      />
                    )}
                  </Link>
                </span>
              </div>
              <div className="col-6 col-12-medium">
                <MarkdownView markdown={post.main_product_pick.description} />
                <OutboundLink href={post.main_product_pick.link} className="button" target="_blank">
                  {post.main_product_pick.linkText || "Shop now"}
                </OutboundLink>
              </div>
            </div>
          </div>

          <div className={`box white-${mode} ball`}>
            <div className="heading">
              <h2>{post.otherPicksTitle}</h2>
            </div>
            <div className="row gtr-150 gtr-uniform">
              {post.products.map((product) => (
                <div key={product.id} className="col-4 col-6-medium">
                  <span className="image fit">
                    <Link to={`/shop/${product.slug}`}>
                      {preview && (
                        <img
                          src={product.images.length && product.images[0].url}
                          alt={
                            product.images.length &&
                            product.images[0].alternativeText
                          }
                        />
                      )}

                      {!preview && (
                        <Image
                          image={product.images.length && product.images[0]}
                        />
                      )}
                    </Link>
                  </span>
                  <h4>{product.name}</h4>
                  <p className="no-margin no-padding">
                    {product.price && (
                      <>
                        {product.discountedPrice && (
                          <del>${product.discountedPrice?.toFixed(2)}</del>
                        )}{" "}
                        <strong>${product.price?.toFixed(2)}</strong>
                      </>
                    )}
                  </p>
                  <OutboundLink href={product.link} className="button" target="_blank" rel="noreferrer">
                    {product.linkText || "Shop now"}
                  </OutboundLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IndexSection.defaultProps = {
  mode: "white",
};

export default IndexSection;
