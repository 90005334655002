import axios from "axios";
import * as React from "react";
import RenderIndexPage from "../components/renderIndexPage";
import { boxModeGen } from "../components/utils";

const PREVIEW_URL = "https://cms.reinventing.co";

const IndexPagePreview = ({ location }) => {
  const boxMode = boxModeGen();
  const [props, setProps] = React.useState({
    preview: true,
    boxMode: boxMode,
    posts: [],
  });

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const slug = params.get("slug");
    const token = params.get("token");
    axios
      .get(
        `${PREVIEW_URL}/ok-carbon-posts?_publicationState=preview&slug=${slug}&token=${token}`
      )
      .then((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          setProps((prev) => ({
            ...prev,
            posts: res.data,
          }));
        }
      });
  }, [location]);

  return (props.posts && <RenderIndexPage {...props} />) || "";
};

export default IndexPagePreview;
