import * as React from "react";
import { CONSTANTS } from "./constants";
import IndexSection from "./indexSection";
import Layout from "./layout";
import Seo from "./seo";

const RenderIndexPage = ({ posts, boxMode, preview }) => {
  return (
    <Layout altBg={false}>
      {!preview && <Seo
        meta={[
          {
            property: `og:url`,
            content: `${CONSTANTS.URL}/`,
          },
        ]}
      />}
      {posts.map((post) => (
        <IndexSection
          key={post.id || post.node.strapiId}
          post={preview ? post : post.node}
          mode={boxMode.next().value}
          preview={preview}
        />
      ))}
    </Layout>
  );
};

export default RenderIndexPage;
